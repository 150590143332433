import React from 'react'

export default ({ className, fullHeight, children }) => {
  const height = fullHeight ? 'h-full' : ''
  return (
    <div className={className + ' py-4 md:p-4'}>
      <div
        className={
          height + ' flex flex-col p-8 pb-6 bg-white-dark shadow text-center'
        }
      >
        {children}
      </div>
    </div>
  )
}

import React from 'react'

class ContactButton extends React.Component {
  constructor(props) {
    super(props)

    this.sendMail = this.sendMail.bind(this)
  }

  sendMail() {
    window.location.href = `mailto:${this.props.name}@mv-winterbach.de`
  }

  render() {
    return (
      <a href="#" onClick={this.sendMail} className={this.props.className}>
        E-Mail schreiben
      </a>
    )
  }
}

export default ContactButton

import React from 'react'
import Layout from '../components/Layout'
import MvTextPage from '../components/common/MvTextPage'
import MvTextPageSection from '../components/common/MvTextPageSection'
import MvPanel from '../components/common/MvPanel'
import MvTextPageHighlight from '../components/common/MvTextPageHighlight'
import MvTextPageInfo from '../components/common/MvTextPageInfo'

function InfoPanel({ displayTitle, anchorTitle, children }) {
  return (
    <MvPanel className="w-full md:w-1/2" fullHeight>
      <h3 className="h-24">{displayTitle}</h3>
      <div className="text-left">{children}</div>
      <a
        href={`#${encodeURI(anchorTitle.toLowerCase())}`}
        className="self-end mt-8 md:mt-auto"
      >
        weitere Informationen
      </a>
    </MvPanel>
  )
}

function PricePanel({ price, interval, primary, hint, children }) {
  const bgColor = primary ? 'bg-primary' : 'bg-white-dark'
  return (
    <span
      className={bgColor + ' text-center inline-block shadow m-4 px-2 py-1'}
    >
      <span className="block">{children}</span>
      <span className="text-lg font-bold">{price}</span> {interval}
      {hint && <sup>*</sup>}
    </span>
  )
}

export default () => (
  <Layout>
    <MvTextPage title="Ausbildung">
      <div className="flex flex-col flex-wrap md:flex-row items-stretch">
        <InfoPanel
          displayTitle="Musikalische &shy;Früherziehung"
          anchorTitle="Musikalische Früherziehung"
        >
          <p>
            Frühmusikalischer Unterricht bei dem musikalische Grundkenntnisse
            aber auch Singen und Sprechen gelehrt werden.
          </p>
          <ul>
            <li>ca. 8 Kinder je Gruppe</li>
            <li>Mindestalter 4 Jahre</li>
            <li>Kursdauer beträgt 2 Jahre </li>
            <li>Kurstag ist Montag</li>
          </ul>
        </InfoPanel>
        <InfoPanel
          displayTitle="Block&shy;flöten&shy;unterricht"
          anchorTitle="Blockflötenunterricht"
        >
          <p>
            Kleingruppenunterricht für musikalische Neueinsteiger und
            Blockfötenfans.
          </p>
          <ul>
            <li>2 bis 5 Kinder je Gruppe</li>
            <li>ca. 45 min Unterricht</li>
          </ul>
        </InfoPanel>
        <InfoPanel
          displayTitle="Instrumental&shy;unterricht"
          anchorTitle="Instrumentalunterricht"
        >
          <p>
            Im Einzelunterricht für Jung und Alt wird wöchentlich am Musizieren
            mit dem Lieblingsinstrument gefeilt. Regelmäßige D- und C-Lehrgänge
            des Blasmusikverbands runden die musikalische Ausbildung ab.
          </p>
          <ul>
            <li>einmal wöchentlich</li>
            <li>30 min Unterricht</li>
            <li>motivierte Ausbilder</li>
          </ul>
        </InfoPanel>
        <InfoPanel
          displayTitle="Jugend&shy;kapelle"
          anchorTitle="Jugendkapelle"
        >
          <p>
            Beim gemeinsamen Musizieren und bei außermusikalischen Aktivitäten
            lernen die Jugendlichen Musik, Gemeinschaftsgefühl und Vereinsleben
            kennen.
          </p>
          <ul>
            <li>einmal wöchentlich</li>
            <li>Probe ist dienstags von 18:30 - 19:30 Uhr</li>
            <li>regelmäßige Auftritte</li>
          </ul>
        </InfoPanel>
      </div>

      <MvTextPageHighlight
        title="Sie haben noch Fragen?"
        subtitle="Kontaktieren Sie uns"
        action="jugendleitung"
      >
        Wenn Sie noch Fragen zum Ausbildungsangebot haben, ihr
        Lieblingsinstrument hier nicht aufgeführt wird oder Sie noch
        unentschlossen sind, dann nehmen Sie gerne Kontakt mit unserer
        Jugendleitung auf.
      </MvTextPageHighlight>

      <MvTextPageSection
        title="Musikalische &shy;Früherziehung"
        anchor="Musikalische Früherziehung"
      >
        <p>
          Die Kinder finden dabei zusammen mit Gleichaltrigen den Zugang zur
          Welt der Musik. Frühzeitige Anregungen und Lernimpulse fördern die
          Entwicklung des musikalischen Gehörs in besonderem Maße.
        </p>
        <p>
          Die musikalische Früherziehung bietet einen höchst positiven Beitrag
          zur Gesamtentwicklung Ihres Kindes. Dabei werden folgende Inhalte
          gelehrt.
        </p>

        <ul className="mb-8">
          <li>Singen und Sprechen</li>
          <li>Elementares Musizieren mit Orff-Instrumenten</li>
          <li>Musik und Bewegung, Bewegung und Tanz</li>
          <li>Hörerziehung</li>
          <li>Instrumentenkunde</li>
          <li>Kennenlernen der Notenschrift</li>
          <li>Erarbeitung musikalischer Grundbegriffe</li>
        </ul>

        <p>
          Der Unterricht orientiert sich an den Schulferien und kommt ab einer
          Teilnehmeranzahl von 5 Kindern zustande.
        </p>

        <div className="flex items-center justify-center">
          <PricePanel price="55€" interval="im Quartal" primary>
            Unterricht
          </PricePanel>
          <span>+</span>
          <PricePanel price="35€" interval="im Jahr">
            Mitgliedschaft eines Elternteils
          </PricePanel>
        </div>
      </MvTextPageSection>

      <MvTextPageSection
        title="Blockflöten&shy;unterricht"
        anchor="Blockflötenunterricht"
      >
        <p>
          Mit dem Blockflötenunterricht haben schon so einige Musikerkarrieren
          begonnen. Die aus der Renaissance und dem Barrock stammende Blockflöte
          führt viele Kinder an die Notenwelt heran. Die Blockflöte gilt
          aufgrund ihrer einfachen Handhabung, damit sind Tonerzeugung und
          Grifftechnik gemeint, als ideales Einsteigerinstrument.
        </p>
        <p>
          Der Musikverein bietet Kleingruppenunterricht von zwei bis maximal
          fünf Kindern an. Die Unterrichtslänge variiert je nach Gruppengröße
          zwischen einer halben und einer drei viertel Stunde. Kurse beginnen,
          sobald sich eine Gruppe bildet, die im Alter zusammenpasst.
        </p>

        <div className="flex items-center justify-center">
          <PricePanel price="55€" interval="im Quartal" primary>
            Unterricht
          </PricePanel>
          <span>+</span>
          <PricePanel price="35€" interval="im Jahr">
            Mitgliedschaft eines Elternteils
          </PricePanel>
        </div>
      </MvTextPageSection>

      <MvTextPageSection
        title="Instrumental&shy;unterricht"
        anchor="Instrumentalunterricht"
      >
        <p>
          Aktuell bieten wir, gemeinsam mit unseren Ausbildern, Einzelunterricht
          für folgenden Instrumenten an:
        </p>
        <p>
          {[
            'Querflöte',
            'Klarinette',
            'Oboe',
            'Saxophon',
            'Trompete',
            'Tenorhorn / Bariton',
            'Horn',
            'Posaune',
            'Tuba',
            'Schlagwerk',
          ].map(instrument => (
            <span
              key={instrument}
              className="bg-white-dark inline-block shadow m-1 px-2 py-1"
            >
              {instrument}
            </span>
          ))}
        </p>
        <p>
          Termine für den Einzelunterricht variiern je nach Ausbilder und werden
          deshalb individuell vergeben.
        </p>
        <div className="flex flex-wrap items-center justify-center">
          <PricePanel price="105€" interval="im Quartal" primary hint>
            1. Kind
          </PricePanel>
          <span>+</span>
          <PricePanel price="30€" interval="im Quartal">
            Gebühr für ein Leihinstrument
          </PricePanel>
          <span>+</span>
          <PricePanel price="35€" interval="im Jahr">
            Mitgliedschaft eines Elternteils
          </PricePanel>
          <p className="text-grey mt-4">
            <sup>*</sup> Unterrichtskosten für das 2. Kind betragen 90€ im
            Quartal, für alle weiteren Kinder 85€.
          </p>
        </div>
      </MvTextPageSection>
    </MvTextPage>

    <MvTextPageSection title="Jugendkapelle">
      <p>
        Einmal in der Woche treffen sich die Jugendlichen zur gemeinsamen Probe.
        Hier wird für einen der jährlichen Auftritte bei örtlichen Festen
        geprobt oder Stücke für ein anstehendes Wertungsspiel einstudiert. Immer
        am Ende jeden Jahres fokussieren sich die Probearbeiten auf das im
        Januar anstehende Jahreskonzert in der Salierhalle.
      </p>
      <p>
        Aber auch bei außermusikalischen Aktivitäten wie Schlittschuhlaufen,
        Zeltwochenende oder Kuchenverkauf zeigt sich, was für eine tolle Gruppe
        die Jugendkapelle ist.
      </p>
    </MvTextPageSection>
  </Layout>
)
